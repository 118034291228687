*, *:before, *:after {
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

html {
  font-family: 'Roboto', sans-serif;
}

html, body {

  background-color: #fff;
}

body {
  color: rgba(0,0,0,0.5);
}

#root {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

figure {
  display: block;
}

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}