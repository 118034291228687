.SwipeGalleryManager {
  display: inline-flex;
  height: 100%;
  
  .SwipeItems {
    overflow: hidden;
    &.mobile-item {
      display: none;
    }
  }
  .SwipeItems, .NextItem {
    padding-right: 25px;
    height: calc(100% - 50px);

    img {
      height: 100%; 
      width: auto;
    }
  }

  .CategoryList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // padding: 40px;

    .item-wrapper {
      margin-bottom: 10px;
      display: flex;
      width: 230px;
      margin-right: 10px;
      flex-wrap: wrap;
      align-content: flex-start;
      flex-direction: column;

      &.image-wrapper {
        img {
          max-width: 100%;
        }
      }


      .year {
        font-weight: 300;
        font-size: 10px;
        height: 100%;
      }
  
      .description-wrapper {
        font-size: 14px;
        padding-left: 12px;
        width: 100%;
        color: #252525;
        margin-bottom: 5px;

        button, p {
          border: none;
          background-color: transparent;
          padding: 0;
          position: relative;
          outline: none;
        }

        button {
          cursor: pointer;
          padding-bottom: 5px;
          margin-bottom: -5px;
        }

        button:hover {
          font-weight: 700;
        }

        button:hover:after {
          right: calc(100% - 8px);
        }
        
        button:after {
          transition: all .3s ease;
          content: '';
          left: 0px;
          right: 0px;
          bottom: 3px;
          border-bottom: 1px solid #252525;
          position: absolute;
        }
      }
    }
  }
}