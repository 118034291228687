.Estudio {
  padding: 0 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all .4s ease-in-out;

  p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #363636;
    a {
      color: inherit;
    }
  }
}