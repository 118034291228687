.Slider {
  .content {
    transition: all 1s ease-in-out;
    height: calc(100% + 100px);
    overflow: auto;
    padding-left: 40px;
  }

  
  .next-work {
    min-width: 150px;
    font-size: 14px;
    border: 0;
    background-color: transparent;
    outline: none;
    color: #3f3f3f;
    position: absolute;
    bottom: 25px;
    right: 25px;
    cursor: pointer;
    padding: 5px;
    transition: all .3s ease;

    &:hover {
      color: #252525;
      transform: translateX(5px)
    }
  }
}