.Gallery {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 75px; 

  .gallery-image-wrapper {
    width: 33.333%;
    padding: 0 0 25px 25px;
    display: block;
    position: relative;
    transition: all .4s ease-in-out;
    &.opacity-0 {
      opacity: 0;
      cursor: default;
      figcaption {
        cursor: default;
      }
    }
    
    img {
      max-width: 100%;
      height: auto;
      display: block;
      padding: 2px;
    }
    
    figcaption {
      align-items: center;
      background: #fff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: calc(100% - 25px);
      justify-content: center;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .2s ease-in;
      padding-left: 26px;
    }

    figcaption:hover {
      opacity:0.7;
    } 

    p {
      font-size: 1em;
    }
  }
}