#root {
  padding: 0 20px;
}

.App {
  min-height: 100vh;
  width: 100%;
  max-width: 1300px;
  display: flex;
  position: relative;
  padding-top: 100px;
  
  
}

aside {
  height: calc(100vh - 100px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 1;

  .view-category-list {
    display: none;

  }
  .logo-wrapper {
    button  {
      padding: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      outline: 0;
    }

    #logo {
      width: 60px;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: calc(50% + 15px);
    width: 100%;

    .mobile-nav-button {
      cursor: pointer;
      display: none;

      
    }

    .close-button {
      position: absolute;
      top: calc(50% - 8px);
      background-color: transparent;
      right: 15px;
      border: 0;
      outline: none;
      cursor: pointer;
      transition: all .3s ease-in-out;
      opacity: 0.5;
      
      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 16px;
      }
    }
    
    ul {
      list-style: none;
      padding: 0;
      
      li {
        position: relative;
        margin-bottom: 5px;
        transition: all .4s ease;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &.not-active {
          opacity: 0.3;
        }

        &.active {
        }

        button {
          border: 0;
          cursor: pointer;
          background-color: transparent;
          outline: 0;
          color: #3f3f3f;
          transition: all .3s ease;
          &:hover {
            color: #040202;
          }

          &.close-button {
            position: absolute;
            left: -20px;
            top: 0;
            color: #ababab;
          }

          &.list-button {
           margin-left: 15px; 
          }

        }
      }
    }
  }
  .description {
    position: absolute;
    left: 0;
    top: calc(50% + 35px);
    transition: all 1s ease-in-out;
    transform: translateY(100vw);
    background-color: transparent!important;
    &.opacity-1 {
      transform: translateY(0);
    }

    ul {
      list-style: none;
      padding: 0;
      padding-left: 6px;
      line-height: 16px;
      font-size: 14px;

      li:last-child {
        max-width: 170px;
      }
    }
  }
  
}

main {
  flex: 3;
  margin-left: 25%;
  margin-top: 90px;
}


.Slider {
  transition: all .8s ease-in-out;
  position: fixed;
  padding: 100px 0px 100px 25px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
}

.instagram-wrapper {
  margin-bottom: 70px;
  a {
    color: #3f3f3f;
    transition: all .3s ease;
    font-size: 14px;

    &:hover {
      color: #040202;
    }
  }

  
}

@media (max-width: 800px) {
  .hidden-mobile {
    display: none!important;
  }
  
  #root {
    padding: 0;
  }

  .App {
    padding: 0;
  }
  
  main {
    margin-left: 0;

    .Gallery {
      padding-bottom: 0;
      .gallery-image-wrapper  {
        width: 100%;
        padding-left: 0;

        &:last-child {
          padding-bottom: 0;
        }

        img {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
  aside {
    padding: 20px;
    width: 100% !important;
    top: 0;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    position: fixed;
    background-color: #fff;
    
    .logo-wrapper {
      #logo {
        width: auto;
        height: 50px;
      }

    }

    nav {
      bottom: initial;
      flex-direction: column;
      position: relative;
      width: calc(100% - 65px);
      align-items: flex-end;

      .mobile-nav-button {
        display: block;
        border: none;
        background-color: transparent;
        padding: 0;
        // height: 70px;
        outline: none;
        margin-top: 10px;
        img {
          height:35px;
        }
      }

      ul {
        // for testing styles
        height: 0;
        text-align: right;
        padding-top: 0;
        overflow: hidden;
        transition: all .5s ease-in-out;

        &.isOpen {
          height: calc(100vh - 89px);
        }
        
        li{
          &:first-child {
            padding-top: 30px;
          }
          display: block;
          margin-bottom: 15px;

          button {
            font-size: 20px!important;
          }
        }
      }

      
      
    }
    & nav.isOpen + div + .instagram-wrapper{
      opacity: 1;
      pointer-events: all;
      transition: all .3s ease-in-out .5s;
    }
    .instagram-wrapper {
      position: fixed;
      bottom: 20px;
      left: 20px;
      // z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: all .3s ease-in-out;

      margin-bottom: 0px;
      font-size: 20px!important;
      a {
        font-size: inherit;
      }
    }
    
  }

  //tests//
  main {
    .Slider {
      // left: 0!important;
      padding: 100px 0;

      .content.isCategoryListOpen {
        width: 100vw!important;
        overflow-x: hidden;
        height: calc(100vh - 100px);
      }
 
      .SwipeItems.mobile-item {
        display: initial;
      }

      .CategoryList {
        width: 100vw !important;
        padding: 0px;
        flex-direction: row;
        flex-wrap: wrap;

        .item-wrapper {
          padding: 0 20px; 
          width: 100%;

          img {
            max-width: 100%;
          }
        }
      }
      
      .content {
        height: 65vh;
        padding: 0!important;
        &.isEstudioOpen {
          height: auto;
        }
      }
      
      &.open {
        left: 0!important;
      }
    }

    .Estudio {
      width: auto!important;
      padding: 20px;
    }
  }
  aside {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right:-20px;
      width: 20px;
      background-color: #fff;
    }
    
    .description {
      top: initial;
      position: fixed;
      right: 0;
      background-color: #fff;
      padding: 20px;
      bottom: 0;

      &.isEstudioOpen {
        opacity: 0;
      }
      &.isCategoryListOpen {
        display: none;
      }
      
      ul {
        li {
          &:last-child {
            max-width: initial;
            margin-right: 70px;
          }
        }
      }
    }
    .close-button {
      display: none!important;
    }
    & .description.opacity-1 {
      .view-category-list {
        display: block;
        cursor: pointer;
      }
    }

    .view-category-list {
      background-color: transparent;
      border: none;
      outline: 0;
      position: fixed;
      bottom: 19px;
      right: 20px;
      

    }
  }
}